import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Barrierefreiheit ist ein Gemeinschaftsprojekt. Damit eine Website für alle Nutzer:innen gut bedienbar ist, sollte
Accessibility im gesamten Entwicklungsprozess berücksichtigt werden. Wie können Designer:innen am besten dazu beitragen?`}</p>
    <p>{`Als Web-Entwickler und Requirements Engineer arbeite ich eng mit UI/UX-Designer:innen zusammen. Auf Basis der Anforderungen
erarbeiten sie Entwürfe und
`}<a parentName="p" {...{
        "href": "https://medium.com/sketch-app-sources/what-is-a-design-prototype-the-complete-guide-f79cce54c50e"
      }}>{`Prototypen für die Benutzeroberfläche`}</a>{`,
die Entwickler:innen später implementieren. Diese Arbeit ist oft sehr visuell dominiert:
Welche Farben kommen zum Einsatz? Wie berücksichtigen wir die Corporate Identity des Kunden optimal?`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAwD/xAAWAQEBAQAAAAAAAAAAAAAAAAACAQP/2gAMAwEAAhADEAAAAQZVzQyVP//EABoQAAICAwAAAAAAAAAAAAAAAAECABEDEBL/2gAIAQEAAQUCdgXRrnJi4xYpdf/EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/ASf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAACAgMAAAAAAAAAAAAAAAABERAhAAKB/9oACAEBAAY/AtR3LLpqUI//xAAcEAACAgIDAAAAAAAAAAAAAAAAARExIUFRgeH/2gAIAQEAAT8hceMTkVXs/YLkLaH0K1o2TFH/2gAMAwEAAgADAAAAEM8v/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARQf/aAAgBAwEBPxAckNv/xAAWEQEBAQAAAAAAAAAAAAAAAAAxAAH/2gAIAQIBAT8QSwv/xAAcEAEAAgIDAQAAAAAAAAAAAAABABEhMUFRcWH/2gAIAQEAAT8Q7Qo16iErhUAAaA+RFpct4jAGzFuVP2Cr3B6DjyGjAn//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Zwei Mitarbeiterinnen eines Blumengeschäfts betrachten das Tablet vor ihnen auf dem Tisch.",
          "title": "Zwei Mitarbeiterinnen eines Blumengeschäfts betrachten das Tablet vor ihnen auf dem Tisch.",
          "src": "/static/4c5c8363d64cafe6061df1b14de4675b/e5166/pexels-amina-filkins-women-tablet.jpg",
          "srcSet": ["/static/4c5c8363d64cafe6061df1b14de4675b/f93b5/pexels-amina-filkins-women-tablet.jpg 300w", "/static/4c5c8363d64cafe6061df1b14de4675b/b4294/pexels-amina-filkins-women-tablet.jpg 600w", "/static/4c5c8363d64cafe6061df1b14de4675b/e5166/pexels-amina-filkins-women-tablet.jpg 1200w", "/static/4c5c8363d64cafe6061df1b14de4675b/b17f8/pexels-amina-filkins-women-tablet.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Amina Filkins / pexels.com`}</em></p>
    <p>{`Bei Barrierefreiheit denken Designer:innen daher oft an die `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/use-of-color.html"
      }}>{`Verwendung von Farbe`}</a>{`
und `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/contrast-minimum"
      }}>{`ausreichende Kontrastverhältnisse`}</a>{`. Doch Designs können
noch mehr: Barrierefreiheits-Anmerkungen vermitteln wichtige Informationen über die Struktur und Semantik der Website.`}</p>
    <h2>{`Das A11y Annotation Kit für Figma`}</h2>
    <p>{`Es gibt verschiedene Programme zum Erstellen von Prototypen. Meine Kolleg:innen arbeiten gerne mit `}<a parentName="p" {...{
        "href": "https://www.figma.com"
      }}>{`Figma`}</a>{`,
einer kollaborativen Plattform für UI/UX Design.`}</p>
    <p>{`Um dort Überlegungen zur Barrierefreiheit zu dokumentieren, können Designer:innen selbst passende Elemente definieren oder mit
fertigen Bibliotheken wie dem `}<a parentName="p" {...{
        "href": "https://www.figma.com/community/file/953682768192596304/a11y-annotation-kit"
      }}>{`A11y Annotation Kit`}</a>{`
arbeiten. Dieses bietet eine breite Palette an Tools, um semantische Informationen im Design auszuzeichnen. Sehen wir uns ein
paar davon näher an.`}</p>
    <h2>{`Überschriften auszeichnen`}</h2>
    <p>{`Sehende Nutzer:innen scannen meist instinktiv eine Website nach Überschriften, um das zu finden, wonach sie suchen. Auch für
blinde und sehbeeinträchtigte Nutzer:innen sind Überschriften von zentraler Bedeutung: Laut der
`}<a parentName="p" {...{
        "href": "https://webaim.org/projects/screenreadersurvey10/#heading"
      }}>{`letzten Umfrage von WebAIM`}</a>{` sagen 9 von 10 Screenreader-Nutzer:innen,
dass sinnvoll eingesetzte Überschriften-Ebenen für sie sehr oder eher nützlich seien.`}</p>
    <p>{`Überschriften müssen semantisch korrekt ausgezeichnet werden, damit Screenreader sie als Überschrift ankündigen können. Bei
Websites passiert das mit den HTML-Tags `}<InlineCode mdxType="InlineCode">{`h1`}</InlineCode>{` bis `}<InlineCode mdxType="InlineCode">{`h6`}</InlineCode>{`. Nutzer:innen können dann
etwa mit Tastaturkürzel von Überschrift zu Überschrift springen und sich somit schnell einen Überblick verschaffen.`}</p>
    <p>{`Designer:innen sollten sich daher möglichst früh überlegen, wie der Inhalt einer Seite
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/tutorials/page-structure/headings/"
      }}>{`sinnvoll mit Überschriften strukturiert`}</a>{`
werden kann. Im finalen Design sollten alle Überschriften sowie ihre Ebene (1 bis 6) markiert sein. Hier ein Beispiel:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.333333333333336%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAACxLAAAsSwGlPZapAAACQklEQVQoz1VSW2saURj0x4WYKATyB6SopemDJW9FMKmNiYi63rD1RiwhfVHiiw9CS9rGuAlqSMVCXjReouINFzU1akSZ8n1FIQeG+c7Z3WFmzsq0Wi10Oh00Gi3U6ld4s/MaKpUKGo0aSqUSCoUCm5ub2NjYYMjlcqyvr2NtbY2fbW9v83tbW1u8l+n1ehgMe9jbN2D33XuEgl+Ry/1GMpnE1dUVRFFkJqRSKVxfXyOXy+Hu7g6RSAR+vx+CIMBgMMBoNELmdrvhcrnx2efCW7UHdtMl/o6GaLfaaLfb6Ha76HQ6PBPP53MsVz6fRzwex9nZGXw+H0KhEGSkTnA6Hfiwb8L3b78gST0Ui0XUajVGtVrFw8MDKpUKz3Q2nU6RyWRwenqKk5MTeL1eFpVZrVbY7XaQU4vFDFFMYTweo9ForFwRBoMBnxNGoxE7pOiJRALRaBROpxOBQOC/Q9qQ4KHpEBcXSf6YnNTrdXZG4jQvHZPTp6cn3N7eIhaLcZfkkCPbbDYWJJjNR0ilkuj3+yiXyy8EiZvNJiRJYqbINzc3HJOcEQeDQcgcDgcEwcEdmg4s+HF+iefnKbscDocsTjPx4+MjJpMJFosFR06n0yBDJEhiq8iC4ITbY8Puzhd8sv6BJHW5P3JBnRHPZjPmJZaCLpeLo5IgR+ZLsdnh8bjx0WjDz/M0v3x/f49SqYRCocAzdbfskkCi1CEJvoj84lKODiCKSRakj6irVqvF/yKh1+utQCubzbJgOBxmsePjY/wDSXW0buM9UAIAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Ein Design mit zwei Überschriften, die als h2 und h3 markiert sind.",
          "title": "Ein Design mit zwei Überschriften, die als h2 und h3 markiert sind.",
          "src": "/static/90dc1bb6b6d4373e8b9572e7beafcec3/c1b63/website_a11y_annotation_headings_de.png",
          "srcSet": ["/static/90dc1bb6b6d4373e8b9572e7beafcec3/5a46d/website_a11y_annotation_headings_de.png 300w", "/static/90dc1bb6b6d4373e8b9572e7beafcec3/0a47e/website_a11y_annotation_headings_de.png 600w", "/static/90dc1bb6b6d4373e8b9572e7beafcec3/c1b63/website_a11y_annotation_headings_de.png 1200w", "/static/90dc1bb6b6d4373e8b9572e7beafcec3/d61c2/website_a11y_annotation_headings_de.png 1800w", "/static/90dc1bb6b6d4373e8b9572e7beafcec3/97a96/website_a11y_annotation_headings_de.png 2400w", "/static/90dc1bb6b6d4373e8b9572e7beafcec3/ae628/website_a11y_annotation_headings_de.png 2604w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Screenshot: © Alexander Lehner`}</em></p>
    <p>{`Zwei Fragen werden euch dabei ständig begleiten: Was macht eine Überschrift zur Überschrift? Welche Überschriften-Hierarchie
ist sinnvoll? Um euch das Leben etwas zu erleichtern, hier ein paar Tipps:`}</p>
    <ul>
      <li parentName="ul">
        <strong>Überschriften strukturieren den Inhalt der Seite:</strong> Eine Überschrift leitet einen neuen Inhaltsabschnitt ein
und sollte diesen beschreiben. Große Schrift, Fettdruck oder eine eigene Zeile machen einen Text nicht automatisch zur Überschrift!
      </li>
      <li parentName="ul">
        <strong>Überspringt niemals eine Überschriften-Ebene!</strong> Die wichtigste Überschrift sollte als <InlineCode mdxType="InlineCode">h1</InlineCode>
ausgezeichnet werden, danach folgt <InlineCode mdxType="InlineCode">h2</InlineCode>, <InlineCode mdxType="InlineCode">h3</InlineCode>, usw. Überschriften mit gleichem oder
höherem Rang beginnen einen neuen Abschnitt. Überschriften mit niedrigerem Rang beginnen neue Unterabschnitte.
      </li>
      <li parentName="ul">
        <strong>Styling und Semantik trennen:</strong> Die visuelle Darstellung einer Überschrift sollte niemals darüber entscheiden,
welche Überschriften-Ebene verwendet wird! Mein Tipp: Definiert eigene Überschriften-Klassen
wie <InlineCode mdxType="InlineCode">heading-sm</InlineCode>, <InlineCode mdxType="InlineCode">heading-md</InlineCode>, <InlineCode mdxType="InlineCode">heading-lg</InlineCode>, usw.
Somit könnt ihr Überschriften unabhängig von ihrer Position in der Hierarchie stylen.
      </li>
      <li parentName="ul">{`In manchen Fällen kann auch eine `}<a parentName="li" {...{
          "href": "https://www.tpgi.com/the-anatomy-of-visually-hidden/"
        }}>{`visuell verborgene Überschrift`}</a>{`
Sinn machen, um die Seitenstruktur zu verbessern.`}</li>
      <li parentName="ul">
        <strong>Seid kreativ!</strong> Eine perfekte Überschriften-Struktur ist harte Arbeit. Macht einen Entwurf. Holt euch Feedback
von außen. Verfeinert eure Designs schrittweise. Es ist noch kein Meister vom Himmel gefallen 😉
      </li>
    </ul>
    <h2>{`Alternativtext für Bilder`}</h2>
    <p>{`Fehlende oder schlechte Alternativtexte zählen zu den häufigsten Barrieren auf Websites. Laut der Studie
`}<a parentName="p" {...{
        "href": "https://webaim.org/projects/million/"
      }}>{`WebAIM Million 2023`}</a>{` weisen über ein Drittel der Bilder auf beliebten Websites
einen fehlenden, fragwürdigen oder sich wiederholenden Alternativtext auf.`}</p>
    <p>{`Design-Prototypen sollten daher Alternativtexte für Bildinhalte definieren, so dass visuell vermittelte Informationen
auch für Screenreader-Nutzer:innen zugänglich sind. Hierbei solltet ihr folgende Punkte beachten:`}</p>
    <ul>
      <li parentName="ul">
        <strong>Funktionale Bilder:</strong> Wenn ein Button oder Link nur ein Bild oder Icon enthält, dann muss der
Alternativtext den Zweck des Elements beschreiben.
      </li>
      <li parentName="ul">
        <strong>Informative Bilder:</strong> Das sind Bilder, die relevante Informationen vermitteln. Hier sollte das Design einen
konkreten Alternativtext vorgeben oder zumindest definieren, dass ein sinnvoller Alternativtext definiert werden muss.
      </li>
      <li parentName="ul">
        <strong>Dekorative Bilder:</strong> Wenn Bilder nur die Seite aufhübschen sollen, dann müssen sie vor assistiven Technologien
verborgen werden. Die Barrierefreiheits-Anmerkung kann dies mit <InlineCode mdxType="InlineCode">alt=""</InlineCode> darstellen.
      </li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.333333333333336%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAACE4AAAhOAFFljFgAAACnUlEQVQoz1WSW0sbURRG59f0H5T2J/TdF8E2jWjEilS8oEVSLBETX8ykpabGzOSiuUwmM5mYtlLbmhrTmHrB4osg2OBDvOAlioggPqwypyj0YXE2nMN31t5sqb+/n8HBQXp7exkYGKCnp4ehoSE6Ozvp6OgQuFwu2tra7nE6nTgcDlpbnbS7XDxzPOe5s5WnT58h+WUZWQ4I/HKAyckg6xsbrK6usr6+TrVapVwuC5aXl1lZWWFnZ0fcpdMaGS1FeOod0XAQZXoKKR6PkZyNCuJRhZyhcXZ6TL2+T71ep1ar/cfBwQHX19c0Gud8/TLPrD5P87BF06sCb94aSKFpBd/bKN5AlDFZRZ7SKFc3KJWWyOfz9PX10dLSQnNzM8FgkKWlJWG3u7vLj+J30uY8TncGh9vAN6kjye9VHnXM8bjzMw9dBR605OjyfuJXpcRiscjExATDw8O43W6SySSVSoXNzU1qtT8Ui0W+fV3AMtIULJ2cmUX6EFLp9iTo9iR5MTJL++sUHxd/U63YMysTi8UYGRlhdHSUUCgkwtbW1kT7hUKBfH4OLaOT0XUMw0SKqCozMYXZmEJMDYmfKj+X0fWsMLLtTNNElmXi8TgLCwtYlsXe3h6pVApdz5Az7TBDIKmqiqJGCCsqwQ9T5Kw88ZkZmpqa7udnP+zq6sLr9RIIBPD5fGxtbWFks8JuOqYLAREYDoexQyORCHat6zqlUkns4/j4OB6Ph7GxMWGaSCTw+/3Cdnt7W5gFVY0nL7NEEiZzlomkKIoIuwu02zg9PeXq6oqLiwsajQbn5+dcXl7e17e3txwfH4t2M7pBNJkTp2ka/wJtwzvS6TRHR0ccHh6KPdzf3+fk5ERwdnYm7m5ubkSgpmlivnnLFKfNXzJniCjIBYNqAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Ein Design mit mehreren Bildern, für die Alternativtexte definiert sind.",
          "title": "Ein Design mit mehreren Bildern, für die Alternativtexte definiert sind.",
          "src": "/static/4b2df1b24c062c3bf42730abd8ca97c3/c1b63/website_a11y_annotation_alt_text_de.png",
          "srcSet": ["/static/4b2df1b24c062c3bf42730abd8ca97c3/5a46d/website_a11y_annotation_alt_text_de.png 300w", "/static/4b2df1b24c062c3bf42730abd8ca97c3/0a47e/website_a11y_annotation_alt_text_de.png 600w", "/static/4b2df1b24c062c3bf42730abd8ca97c3/c1b63/website_a11y_annotation_alt_text_de.png 1200w", "/static/4b2df1b24c062c3bf42730abd8ca97c3/d61c2/website_a11y_annotation_alt_text_de.png 1800w", "/static/4b2df1b24c062c3bf42730abd8ca97c3/058d6/website_a11y_annotation_alt_text_de.png 2277w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Screenshot: © Alexander Lehner`}</em></p>
    <p>{`Weitere Infos sowie einige Negativbeispiele findet ihr in meinem
Artikel `}<a parentName="p" {...{
        "href": "/de/how-not-to-use-images.de/"
      }}>{`„Ein Bild sagt mehr als tausend Worte – außer du bist blind!“`}</a>{`.`}</p>
    <h2>{`Button oder Link?`}</h2>
    <p>{`Links sind unterstrichen. Buttons sind klickbare Boxen. Oder? 🤔`}</p>
    <p>{`Visuell sind Links und Buttons oft nicht klar voneinander zu unterscheiden. In manchen Designs sehen Links wie klassische
Buttons aus, und umgekehrt. Für sehende Nutzer:innen ist das meistens kein Problem: Hauptsache sie verstehen, dass es sich
um interaktive Elemente handelt.`}</p>
    <p>{`Für assistive Technologien ist die semantische Rolle der Elemente jedoch besonders wichtig. Je nachdem, ob der Screenreader
ein Element als Link oder Button ankündigt, erwarten Nutzer:innen ein anderes Verhalten:`}</p>
    <ul>
      <li parentName="ul">
        <strong>Links</strong> führen zu einer neuen Ressource, z.B. einer anderen Website oder einem anderen Abschnitt auf
derselben Seite. Man verlässt den aktuellen Kontext.
      </li>
      <li parentName="ul">
        <strong>Buttons</strong> lösen hingegen eine Aktion im selben Kontext aus, z.B. ein Popup-Menü oder das Abspielen eines Videos.
      </li>
    </ul>
    <p>{`Das heißt: Das Aussehen ist zweitrangig. Die Funktion des Elements ist entscheidend. Ich empfehle euch den Artikel
`}<a parentName="p" {...{
        "href": "https://marcysutton.com/links-vs-buttons-in-modern-web-applications"
      }}>{`„Links vs. Buttons in Modern Web Applications“`}</a>{`,
der noch tiefer in das Thema eintaucht.`}</p>
    <p>{`Also liebe Designer: Markiert Links und Buttons entsprechend ihrer Funktion. Und erklärt am besten in der Legende
des Designs, dass Entwicklerinnen dafür native Elemente verwenden sollten. Also `}<InlineCode mdxType="InlineCode">{`a`}</InlineCode>{` für Links
und `}<InlineCode mdxType="InlineCode">{`button`}</InlineCode>{` für Buttons. Eure Nutzer:innen werden es euch danken! 🤩`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      